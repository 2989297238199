import React, { useContext, useEffect, useState } from 'react';
import isStrongPassword from 'validator/lib/isStrongPassword';

import { FormContext } from '../context';
import Label from '../label';

import loadComponents from '../../Loadable';
import ButtonHidden from '../../Button/hidden';
import Icons from '../../Icons';

const Alert = loadComponents('alert');

export default function Input({ field }) {
  const { type, className, ...fieldProps } = field;

  const [warning, setWarning] = useState(true);
  const [fieldType, setFieldType] = useState(field.type);
  const { getValue, handleChange } = useContext(FormContext);

  const fieldVal = getValue(field.id);
  const isPassword = field.type === 'password';

  useEffect(() => {
    if (isPassword && fieldVal) {
      const checkPassword = isStrongPassword(fieldVal);
      setWarning(!checkPassword);
    }
  }, [fieldVal]);

  const showPassword = () => {
    if (isPassword) {
      const newVal = fieldType === 'password' ? 'text' : 'password';
      setFieldType(newVal);
    }
  };

  return (
    <label htmlFor={field.id}>
      <Label label={field.label} required={field.required} />
      <div style={{ position: `relative` }}>
        <input
          className={`${className || ``}${isPassword ? ` password` : ``}`}
          onChange={handleChange}
          {...fieldProps}
          type={fieldType}
        />
        {isPassword && (
          <ButtonHidden
            onClick={() => showPassword()}
            style={{
              position: `absolute`,
              top: `50%`,
              right: `20px`,
              transform: `translate(0,-50%)`,
              lineHeight: 1,
            }}
          >
            {fieldType === field.type ? <Icons.Reveal /> : <Icons.Hide />}
          </ButtonHidden>
        )}
      </div>
      {isPassword && warning && field.hint && (
        <Alert info style={{ marginTop: `10px` }}>
          Password must contain 8 characters including uppercase, lowercase,
          number & a symbol.
        </Alert>
      )}
    </label>
  );
}
